import React, { useEffect, useRef, useCallback, useState, useMemo } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import MainNavigationForEinvoice from './components/MainNavigation/MainNavigationForEinvoice'
import useAppDispatch from './hooks/useAppDispatch'
import { startLogin } from './redux/features/auth/authSlice'
import { fetchCurrentPlan } from "./redux/features/subscription/subscriptionSlice";
import RoutesComponent from "./routes";
import withRouter from "helpers/withRouter";
import MainNavigation from "./components/MainNavigation";
import { Frame, TopBar } from "@shopify/polaris";
import Logo from "./img/logo.svg";

import "react-datepicker/dist/react-datepicker.css";
import RenderTopMenus from "./components/TopMenu/RenderTopMenus";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUser } from "./redux/features/user/userSlice";
import { createCustomDispatch } from "helpers/customDispatch";
import RoutesForEinvoice from './routes/routesForEinvoice'

const App = (props) => {
	const {loggingIn, isLogged, user, i18n, defaultLanguage} = props;
	const {fetchUser, fetchCurrentPlan} = props;
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();
	
	const skipToContentRef = useRef(null);
	const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
	const [userMenuActive, setUserMenuActive] = useState(false);
	const isUnsubscribePage = location.pathname.includes("/unsubscribe");
	const lastSubdomainRef = useRef(null)
	
	function getCookie(name) {
		const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
		return match ? decodeURIComponent(match[2]) : null;
	}
	
	const currentSubdomain = useMemo(() => {
		const host = window.location.hostname;
		console.log('host', host)
		if (host.includes('invoice')) return 'invoice';
		if (host.includes('localhost')) return 'invoice';
		// if (host.includes('localhost')) return 'main';
		return 'main';
	}, []);
	
	useEffect(() => {
		const req = async ()  => {
			const getApiKey2 = getCookie("API-KEY");
			const getApiKey = getCookie("api_key");
			console.log('getApiKey', getApiKey)
			console.log('getApiKey2', getApiKey2)

			if ((getApiKey || getApiKey2) && lastSubdomainRef.current !== currentSubdomain) {
			lastSubdomainRef.current = currentSubdomain;
				await fetchUser().catch(_.noop);
				if (isLogged) {
					fetchCurrentPlan();
				}
		}
		}

	}, [currentSubdomain]);
	// useEffect(() => {
	// 	const tryLoginViaMain = async () => {
	// 		const getApiKey2 = getCookie("API-KEY");
	// 		const getApiKey = getCookie("api_key");
	// 		const hasAuth = Boolean(getApiKey || getApiKey2);
	//
	// 		const mainDomain = 'dev.vatcompliance.co';
	// 		const currentHost = window.location.hostname;
	// 		const isOnInvoiceSubdomain = currentHost.startsWith('invoice.');
	//
	// 		// если на invoice. и нет куки — редиректим на основной домен с ?redirect=
	// 		if (isOnInvoiceSubdomain && !hasAuth) {
	// 			const redirectUrl = encodeURIComponent(window.location.href);
	// 			window.location.href = `https://${mainDomain}/login?redirect=${redirectUrl}`;
	// 			return;
	// 		}
	//
	// 		if (hasAuth && lastSubdomainRef.current !== currentSubdomain) {
	// 			lastSubdomainRef.current = currentSubdomain;
	// 			await fetchUser().catch(_.noop);
	// 			if (isLogged) {
	// 				fetchCurrentPlan();
	// 			}
	// 		}
	// 	};
	//
	// 	tryLoginViaMain();
	// }, [currentSubdomain]);
	//
	// useEffect(() => {
	// 	const redirectUrl = new URLSearchParams(window.location.search).get("redirect");
	// 	if (redirectUrl) {
	// 		window.location.href = redirectUrl;
	// 	}
	// }, []);

	
	useEffect(() => {
		fetchUser().catch(_.noop);
		if (isLogged) {
			fetchCurrentPlan();
		}
	}, [isLogged, fetchUser, fetchCurrentPlan, navigate]);
	
	useEffect(() => {
		const frame = document.querySelector(".Polaris-Frame");
		if (!isUnsubscribePage && isLogged) {
			frame.style.backgroundColor = "inherit";
		} else {
			frame.style.backgroundColor = "white";
		}
	}, [isLogged, isUnsubscribePage]);
	
	useEffect(() => {
		if (defaultLanguage) {
			i18n.changeLanguage(defaultLanguage?.lang || "en");
		}
	}, [user, defaultLanguage]);
	
	const toggleUserMenuActive = useCallback(
		() => setUserMenuActive((userMenuActive) => !userMenuActive),
		[]
	);
	
	const toggleMobileNavigationActive = useCallback(
		() =>
			setMobileNavigationActive(
				(mobileNavigationActive) => !mobileNavigationActive
			),
		[]
	);
	
	const userMenuMarkup = (
		<TopBar.UserMenu
			actions={[{items: [{content: "Log out"}]}]}
			open={userMenuActive}
			onToggle={toggleUserMenuActive}
		/>
	);
	
	const topBarMarkup = (
		<TopBar
			showNavigationToggle
			userMenu={userMenuMarkup}
			onNavigationToggle={toggleMobileNavigationActive}
		/>
	);
	
	return (
		<div className='App'>
      {
	      currentSubdomain === 'main'
		      ?
		      <Frame
			      logo={{
				      width: 100,
				      topBarSource: Logo,
				      contextualSaveBarSource: Logo,
				      url: "/",
				      accessibilityLabel: "Lovat",
			      }}
			      showMobileNavigation={mobileNavigationActive}
			      onNavigationDismiss={toggleMobileNavigationActive}
			      skipToContentTarget={skipToContentRef.current}
			      topBar={isLogged ? topBarMarkup : null}
			      navigation={
				      !isUnsubscribePage && isLogged && <MainNavigation user={user} />
			      }
		      >
            {!isUnsubscribePage && isLogged && <RenderTopMenus />}
                <RoutesComponent
                  isLogged={isLogged}
                  isLoggingIn={loggingIn}
                />
          </Frame >
		      :
		      <Frame
			      logo={{
				      width: 100,
				      topBarSource: Logo,
				      contextualSaveBarSource: Logo,
				      url: "/",
				      accessibilityLabel: "Lovat",
			      }}
			      showMobileNavigation={mobileNavigationActive}
			      onNavigationDismiss={toggleMobileNavigationActive}
			      skipToContentTarget={skipToContentRef.current}
			      topBar={isLogged ? topBarMarkup : null}
			      navigation={
				      !isUnsubscribePage && isLogged && <MainNavigationForEinvoice user={user} />
			      }
		      >
            {!isUnsubscribePage && isLogged && <RenderTopMenus />}
                <RoutesForEinvoice
                  isLogged={isLogged}
                  isLoggingIn={loggingIn}
                />
          </Frame >
	      
      }
      
    </div >
	);
};

const mapStateToProps = (state) => ({
	user: state.user.user,
	currentPlan: state.subscription.currentPlan,
	loggingIn: state.auth.loggingIn,
	isLogged: state.auth.isLogged,
	defaultLanguage: state.user.defaultLanguage,
});

const mapDispatchToProps = (defaultDispatch) => {
	const dispatch = createCustomDispatch(defaultDispatch);
	
	return {
		fetchUser: () => dispatch(fetchUser()),
		fetchCurrentPlan: () => dispatch(fetchCurrentPlan()),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation()(withRouter(App)));
