import useAppDispatch from "hooks/useAppDispatch";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, ButtonGroup, FormLayout, TextField } from "@shopify/polaris";
import Modal from "components/Modal";
import { addTaxToList, editTaxInList } from '../../../../redux/features/documents/documentsSlice'

const Tax = (props) => {
  const dispatch = useAppDispatch();
  const { data, onClose: handleClose, isOpen } = props;
  const [taxInfo, setTaxInfo] = useState({});
  const { addingTaxLoading, editingTaxLoading } = useSelector(
    (state) => state.documents
  );

  const clearTaxInfo = () => setTaxInfo({});

  useEffect(() => {
    const { type, ...taxInfo } = data;

    if (type === "update") {
      setTaxInfo(taxInfo);
    }
  }, [data]);

  const getModalPropertiesByType = (type) => {
    switch (type) {
      case "create":
        return {
          loading: addingTaxLoading,
          title: "Add new tax",
          submitAction: addTaxToList,
          submitButton: "Submit",
        };
      case "update":
        return {
          loading: editingTaxLoading,
          title: `Edit tax ${data?.name}`,
          submitAction: editTaxInList,
          submitButton: "Edit",
        };
      default:
        return {};
    }
  };

  const { loading, title, submitAction, submitButton } =
    getModalPropertiesByType(data?.type);

  const onClose = () => {
    handleClose();
    clearTaxInfo();
  };

  const onSubmit = () => {
    dispatch(submitAction(taxInfo)).then(() => {
      onClose();
    });
  };

  const onChange = (id, newValue) => {
    setTaxInfo((prev) => ({
      ...prev,
      [id]: newValue,
    }));
  };

  return (
    <Modal
      title={title}
      infoIconVisible={false}
      visible={isOpen}
      onClose={onClose}
      content={
        <>
          <br />
          <FormLayout>
            <TextField
              id='name'
              type='text'
              inputMode='text'
              placeholder='Enter tax name'
              label='Tax name'
              value={taxInfo.name}
              onChange={(newValue) => onChange("name", newValue)}
            />

            <TextField
              id='rate'
              type='number'
              inputMode='number'
              placeholder='Enter rate'
              label='Rate'
              value={taxInfo.rate}
              onChange={(newValue) => onChange("rate", newValue)}
            />
          </FormLayout>
        </>
      }
      footer={
        <ButtonGroup fullWidth>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant={'primary'} onClick={onSubmit} loading={loading}>
            {submitButton}
          </Button>
        </ButtonGroup>
      }
    />
  );
};

export default Tax;
